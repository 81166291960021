import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const Routes = [
    { path: '*', redirect: '/search' },
    {
        path: '/search',
        name: '物流搜索',
        component: () => import('@/views/index')
    }

]

const createRouter = () => new Router({
    routes: Routes
})

const router = createRouter()

export default router;